.navbar {
    background: #fff !important;
}

.navbar .nav-link {
    font-weight: 500;
    margin-left: 10px;
    /* color: #fff !important; */
    color: #000;
}

.bottom-nav a {
    font-size: 15px !important;
}

.navbar-light .navbar-brand {
    color: #fff !important;
}

.topbar_inner {
    /* background-color: rgba(28, 25, 25, 0.7) !important; */
    box-shadow: 0 0 30px rgb(0 0 0 / 10%)   !important;
}

.navbar-light .navbar-nav .nav-link.active {
    color: #002B8E !important;
    font-weight: 900;
}

.top-nav a {
    font-size: 12px;
    display: inline-block;
    position: relative;
}

.top-nav a:hover {
    font-weight: 800;
}

.top-nav a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #002B8E;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .top-nav a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

.top-nav svg {
    font-weight: 600 !important;
}

.bottom-nav a {
    position: relative;
    display: inline-block;
    transition: 0.25s ease all;
}

.bottom-nav a.active:after, .bottom-nav a:hover:after {
    content: '';
    position: absolute;
    width: 100%;
    /* transform: scaleX(0); */
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: red;
    transition: 0.25s ease all;
}

.instructor-img {
    height: 300px !important;
    object-fit: contain;
    width: 100%;
}

.program-page {
    text-align: justify !important;
}

@media (max-width: 769px) {
    .bottom-nav a.active:after {
        width: 10%;
    }
}

@media (max-width: 428px) {
    /* .navbar {
        background-color: rgba(28, 25, 25, 0.7) !important;
        box-shadow: 0 0 30px rgb(0 0 0 / 10%)   !important;
    } */
    .topbar_inner {
        background-color: rgba(28, 25, 25, 0.7) !important;
        box-shadow: 0 0 30px rgb(0 0 0 / 10%)   !important;
    }

    .bottom-nav a.active:after {
        width: 20%;
    }
}

.navbar-light .navbar-toggler {
    color: #000 !important;
    border-color: #000 !important;
}

.navbar-brand img {
    width: 200px !important;
}

@media (max-width: 427px) {
    .navbar .nav-link {
        margin-left: 0px !important;
    }

    .section-heading {
        font-size: 30px;
    }
}