footer {
    /* background: #C2D4FF; */
    background-color: #f4f7f9;
    color: rgba(55,65,81,1);
}

footer a {
    color: rgba(55,65,81,1);;
    text-decoration: none;
    font-size: 14px;
}

footer a:hover {
    color: #5CADE6 !important;
}

footer ul {
    list-style: none;
    padding-left: 0;
    font-size: 14px;
}

footer ul li {
    margin-bottom: 10px;
}

.top-section {
    border-bottom: 1px solid #fff;
}

.btn-primary {
    background: #002B8E !important;
    border: 1px solid #002B8E !important;
}

.text-primary {
    color: #002B8E !important;
}

footer .section-title {
    /* color: #002B8E !important; */
    color: #5CADE6;
    font-weight: 900;
}

.contact-heading {
    width: 8%;
}

.contact-info {
    width: 80%;
}

.social-icons {
    width: 50%;
    margin-top: 20px;
}

.social-icons li {
    margin-right: 15px;
}

.social-icons li a{
    border: 2px solid rgba(55,65,81,1);
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 15px;
}

.social-icons li a:hover{
    background: #fff;
    color: #002B8E;
}

@media (max-width: 426px) {
    .social-icons {
        width: 80%;
    }
}