/* linear-gradient(to bottom, rgba(19, 71, 160, 0.06), rgba(0, 0, 0, 0.2)) */
.hero-bg-one {
    background: linear-gradient(45deg, #00000074, #0000007c), url(../../images/slide1.jpg) no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.hero-bg-two {
    background: linear-gradient(45deg, #00000074, #0000007c), url(../../images/slide-2.jpg) no-repeat;
    ;
    background-size: cover;
    background-position: center;
}

.hero-bg-three {
    background: linear-gradient(45deg, #00000074, #0000007c), url(../../images/slide-3.jpg) no-repeat;
    background-size: cover;
    background-position: center;
}

.hero-bg-four {
    background: linear-gradient(45deg, #00000074, #0000007c), url(../../images/slide-4.jpg) no-repeat;
    background-size: cover;
    background-position: center;
}

.hero-bg-five {
    background: linear-gradient(45deg, #00000074, #0000007c), url(../../images/slide-5.jpg) no-repeat;
    background-size: cover;
    background-position: center;
}

/* .hero-bg-five {
    background: linear-gradient(to bottom, rgba(19, 71, 160, 0.06), rgba(0, 0, 0, 0.2)),url(../../images/pic-5.jpg) no-repeat;
    background-size: cover;
    background-position: center;
} */

.hero-bg {
    height: 100vh;
    /* display: flex;
    align-items: flex-start;
    justify-content: space-between; */
    color: #fff;
    flex-direction: column;
}

.slider-content {
    padding-top: 200px;
    width: 60%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-content h1 {
    font-size: 3rem;
}

.slider-content p {
    font-size: 18px;
    width: 75%;
}

.brand-name {
    padding: 30px;
}

.brand-name h1 {
    color: blue;
    font-size: 30px;
}

.brand-name span {
    color: #000
}

.why-section {
    /* background: linear-gradient(left ), url(../../images/pic-5.jpg) no-repeat; */
    background: url(../../images/IS3.png) no-repeat;
    background-position: center;
    background-size: cover;
    color: #fff;
}

.why-section h1 {
    color: #fff;
    font-weight: 900;
    font-size: 48px;
    /* text-align: center; */
    padding-top: 100px;
}

.why-section img {
    width: 100px
}

.lower-row {
    width: 70%;
    margin: 0 auto;
}

.reason-box {
    text-align: center;
    margin: 0 auto;
    position: relative;
    transition: all 0.8s ease-in-out;
    cursor: pointer;
}

.reason-note {
    position: absolute;
    background: white;
    width: 100%;
    top: 0;
    color: #000;
    display: none;
    padding: 20px;
    border-radius: 16px;
    border-bottom: 2px solid #000;
    border-left: 1px solid #000;
    z-index: 40;
}




.reason-box .icon-box svg {
    font-size: 70px;
    color: #2ebae1;
}

.reason-box h3 {
    font-size: 18px;
    padding: 20px 0 0px 0;
    font-weight: 600;
}

.reason-box p {
    font-size: 16px;
}

.reason-box:hover .reason-note {
    display: block;
}

#track-records h2 {
    padding: 60px 20px 30px 20px;
}

.counts {
    padding: 60px 20px;
    background: #F5F8FF;
}

.counts .count-box {
    padding-bottom: 15px;
}

.count-number {
    color: #002B8E;
    font-size: 50px;
    margin-right: 10px;
    font-weight: 700;
    padding-bottom: 8px;
}

.counts p {
    font-size: 18px;
    font-weight: 400;
    color: #000;
}


/* .count-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 50%;
    text-align: center;
    width: 150px;
    height: 150px;
    margin: 0 auto;
} */


.school-program {
    background: linear-gradient(to bottom, rgba(189, 208, 242, 0.5), rgba(0, 0, 0, 0.123));
    /* min-height: 400px; */
    /* padding: 20px; */
    border-radius: 8px;
    /* perspective: 1000px; */
    cursor: pointer;
    height: 100%;
    position: relative;
    transition: 0.3s;
}

.school-program-inner {
    height: auto;
}

.school-program::before {
    content: "";
    background: rgba(36, 135, 206, 0.6);
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
}

.school-program .card-back::before {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    top: 15px;
    left: 15px;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    transition: all 0.5s ease 0s;
    z-index: 9994;
}

.school-program .card-back::after {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
    transition: all 0.5s ease 0s;
    z-index: 9994;
}

.school-program:hover::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
}

.school-program img {
    /* height: 313px; */
    border-radius: 12px;
    /* object-fit: cover;
    object-position: center; */
}

.program img {
    height: 600px
}

/* .school-program-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
} */

/* .school-program:hover .school-program-inner {
    transform: rotateY(180deg);
} */

/* .card-back {
    background: #2ebae1;
    color: #fff;
    transform: rotateY(180deg);
    padding: 20px;
} */

.school-program .card-front {
    position: relative;
}

.school-program:hover .card-front {
    opacity: 0;
}

.school-program .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    /* -webkit-backface-visibility: hidden;
    backface-visibility: hidden; */
    top: 0;
    opacity: 0;
    padding: 30px;
    z-index: 3;
    color: #fff;
}

.school-program:hover .card-back {
   opacity: 1;
}

/* .school-program:hover .card-back {
    opacity: 1;
} */


/* .school-program:hover .card-front {
    display: none;
} */

.card-front h3 {
    padding: 15px;
    font-size: 24px;
}

.card-back h3 {
    color: #fff;
    font-weight: 900;
}

.btn-outline-primary {
    border-color: #002B8E !important;
    color: #002B8E !important;
}

.btn-outline-primary:hover {
    color: #fff !important;
    background: #002B8E !important;
}

/* .partners {
    width: 50%;
    margin: 0 auto;
} */

.partner {
    text-align: center;
}

.scheme-list {
    list-style: none;
    padding-left: 0;
}

.section-heading {
    font-size: 40px;
    font-weight: 700;
}

.partner-slider {
    background: #bbe3ef;
}


@media (max-width: 769px) {
    .slider-content {
        width: 80%;
        padding-top: 150px;
    }

    .slider-content h1 {
        font-size: 35px;
    }

    .section-heading {
        font-size: 30px;
    }
}

@media (max-width: 428px) {
    .why-section h1 {
        padding-top: 100px;
        color: #fff;
    }

    /* .reason-box .icon-box {
        width: 130px;
        height: 130px;
    } */

    .reason-box h3 {
        font-size: 20px;
    }

    .partners {
        width: 100%;
    }

    .partner {
        width: 100%;
    }

    .slider-content {
        width: 100%;
    }

    .slider-content h1 {
        font-size: 30px;
    }

    .slider-content p {
        width: 100%;
    }

    .lower-row {
        width: 100%;
    }

    .counts .count-number {
        font-size: 36px;
    }
}