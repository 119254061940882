.testifier {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}

.testimony-section {
    padding: 100px 0;
    background: #002B8E;
}

.testimonial-card {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    min-height: 250px;
    position: relative;
}

.testimonial-card .person-info-test {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 20px 20px 20px;
}

.page-header {
    width: 70%;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .testimonial-card {
        min-height: 380px;
    }
    
}

@media (max-width: 426px) {
    .testimonial-card {
        min-height: 360px;
    }
}